import React from "react";
import './Stories.css';

function Stories() {

    return (
        <div className="Stories">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Fanzone
                        </h1>
                        <p>
                            Bald gibt es hier mehr zu sehen!
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Stories;