import React from 'react';
import './Home.css';

function Home() {

  return (
    <div className="Home">
      <main>
        
        <div style={{"backgroundColor": "transparent", "justifyContent": "center", "display": "flex", "width": "100%", justifyItems: "center"}}>
          
          <iframe 
            style={{"borderRadius": "12px"}} 
            src="https://open.spotify.com/embed/show/4iXKH7U1zODcNEpWkHzGso?utm_source=generator" 
            height="152" frameBorder="0" 
            allowfullscreen=""
            width={"88%"}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy">
          </iframe>

        </div>

        <div className="grid-container">
          <div className="grid-item" style={{"gridArea": "1 / 1 / 2 / 2", "minWidth": "42vw", "minHeight": "400px"}}>
            <h1>
              Aktuelles Video
            </h1>
            <iframe 
              width="100%" 
              height="315" 
              src="https://www.youtube.com/embed/9F405mnANv8?si=O05OL3NBGXEy2AOo" 
              title="YouTube video player" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" 
              allowfullscreen>  
            </iframe>
            {/*
            <p>
              <iframe 
                allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" 
                frameborder="0" 
                height="450" 
                width="100%"
                style={{ overflow: "hidden", borderRadius: "10px" }} 
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" 
                src="https://embed.podcasts.apple.com/de/podcast/pieper-und-paulus-geschichten-f%C3%BCr-kinder-von-5-bis-11/id1737887375">
              </iframe>
            </p>
            */}
          </div>
          <div className="grid-item" style={{ gridArea: "1 / 2 / 2 / 3", minHeight: "400px"}}>
            <h1>
              Über Pieper und Paulus
            </h1>
            <p>
              Sehr herzlich willkommen auf der Seite von dem neugierigen, kleinen, frechen Vogel Pieper! 
              (Und natürlich ist das auch die Seite von seinem Freund Antonius Paulus - 
              aber der legt nicht so viel Wert auf solche Auftritte im Internet...).
            </p>
            <p>
              Du kannst die tollen Abenteuer der beiden auf Spotify, YouTube, Apple Podcasts, podcast.de, 
              Amazon Music, Deezer und anderen Plattformen kostenlos und werbefrei anhören. Damit das so 
              kostenlos und werbefrei bleibt (und weil die Abenteuer den kleinen Vogel natürlich auch hungrig machen), 
              kannst Du Pieper hier auf dieser Seite ein bisschen Vogelfutter spendieren!
            </p>
            <p>
              Liebe Grüße! 
            </p>
            <p>
              Dein Jan Rimpl
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
