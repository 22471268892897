import React from "react";
import './Stories.css';

function Stories() {

    return (
        <div className="Stories">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Unterstützung
                        </h1>
                        <p>
                            Unterstütze Pieper & Paulus durch eine einmalige oder wiederkehrende Spende!
                        </p>
                        <h4>
                            <a href="https://buymeacoffee.com/pieperundpaulus" style={{color: "white"}}>
                                Spende über <i>Buy me a coffee!</i>
                            </a>
                        </h4>
                        <h4>
                            <a href="" style={{color: "white"}}>
                                Spende via <i>PayPal</i>!
                            </a>
                        </h4>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Stories;