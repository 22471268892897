import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {

    return (
        <div className="Footer">
            <div className="grid-container">
                <div className="grid-item">
                    <div className={"footer-item"} style={{ display: "inline-block", margin: "10px", color: "whitesmoke", textDecoration: "none" }}>
                        <Link to="impressum" style={{color: 'whitesmoke'}}>
                            Impressum
                        </Link>
                    </div>
                    <div className={"footer-item"} style={{ display: "inline-block", margin: "10px", color: "whitesmoke", textDecoration: "none" }}>
                        <Link to="disclaimer" style={{color: 'whitesmoke'}}>
                            Disclaimer
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;