import React from "react";
import './Impressum.css';

function Impressum() {

    return (
        <div className="Impressum">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Impressum
                        </h1>
                        <p>
                            Jan Rimpl
                            <br/>
                            Van-Dyck-Straße 1
                            <br />
                            65195 Wiesbaden 
                        </p>
                        <p>
                            Telefon 0049 (0)172 405 85 70
                            <br />
                            pieperundpaulus@web.de
                        </p>
                        <p>
                            Alle Rechte bei Jan Rimpl, {new Date().getFullYear()}.
                        </p>
                        <p>
                            Pieper ist ein eingetragenes Markenzeichen.
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Impressum;