import React from "react";
import './Stories.css';

function Stories() {

    return (
        <div className="Stories">
            <main>
                <div className="grid-container">
                    <div className="grid-item" style={{width: "100%"}}>
                        <h1>
                            Kontakt
                        </h1>
                        <p>
                            Falls du Pieper einen Brief senden möchtest, ob elektronisch oder auf Papier, sende ihn an:
                        </p>
                        <h4>
                            <p>
                                <a href="mailto:pieper@pieperundpaulus.de" style={{color: "white"}}>
                                    pieper@pieperundpaulus.de
                                </a>
                            </p>
                        </h4>
                        <p>
                            oder
                        </p>
                        
                        <h4>
                            <p>
                                Vorname Nachname
                                <br/>
                                Straße Hausnummer
                                <br/>
                                Postleitzahl Bundesland
                            </p>
                        </h4>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Stories;